import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',         icon:'nc-pin-3',           class: '' },
    { path: '/list',          title: 'List',              icon:'nc-align-left-2',    class: '' },
    { path: '/settings',      title: 'Settings',          icon:'nc-settings',        class: '' }
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    constructor(private http: HttpClient,private router: Router) { }

    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    logout(){
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ localStorage.getItem("accessToken")
        };
        let body = {
            refreshToken: localStorage.getItem("refreshToken")
        };
        this.http.post<any>('https://app.iofrog.com/api/v2/auth/token/reject', body ,{headers}).subscribe((data) => {
            localStorage.clear();
            this.router.navigate(['login'], { queryParams: { logout: true } });
        }, (error) => {
            console.log(error);
        });
    }

    propagateClick(){
        document.getElementById('menu-toggle').click();
    }
}
