import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import 'particles.js/particles';
declare let particlesJS: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  error:any = false;
  userID: any;
  firstname: any;
  lastname: any;
  username: any = "";
  email: any;
  lang: any = "en";
  password: any;
  repeatPassword: any;
  loading: boolean = false;
  userData: any;

  constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient) { }

  ngOnInit(): void {
    particlesJS.load('particles-js', '../../../assets/particles.json', function() {
      console.log('callback - particles-js config loaded');
    });
    this.route.queryParams
      .subscribe(params => {
        this.userID = params.userId;
      }
    );
    this.getUserInfo();
  }

  getUserInfo(){
    this.http.get<any>('https://admin.iot-tracking.com/api/v2/auth/finishsignup/'+this.userID, {}).subscribe((data) => {
      this.loading = false;
      if(data != 0){
        this.userData = data;
        this.username = data.username;
        this.email = data.email;
        this.lang = data.lang;
      }else{
        this.error = true;
      }
    }, (error) => {
      this.loading = false;
      this.error = true;
    });
  }

  finishSignup(){
    if(this.firstname != '' && this.lastname != '' && this.username != '' && this.email != '' && this.password != '' && this.repeatPassword != ''){
      if(this.password === this.repeatPassword){
        let body = {
          firstName: this.firstname,
          lang: this.lang,
          lastName: this.lastname,
          password: this.password,
          password2: this.repeatPassword,
          timezone: "Europe/Paris",
          username: this.username
        };

        this.http.put<any>('https://admin.iot-tracking.com/api/v2/auth/finishsignup/'+this.userID, body).subscribe((data) => {
          this.loading = false;
          if(data != 0){
            this.router.navigate(['login'], { queryParams: { email: this.email } });
          }else{
            this.error = true;
          }
        }, (error) => {
          this.loading = false;
          this.error = true;
        });
      }
      else{
        this.error = true;
      }
    }
    else{
      this.error = true;
    }
  }

}
